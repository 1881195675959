import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "@formio/react";

const CatchAllForm: React.FC = () => {
  const { formId } = useParams<Record<string, string>>();
  const authoringPath = process.env.REACT_APP_AUTHORING_PATH;
  const builderUrl = process.env.REACT_APP_BUILDER_URL;

  if (!authoringPath) {
    console.error("REACT_APP_AUTHORING_PATH is not defined in the environment variables");
  }
  if (!builderUrl) {
    console.error("REACT_ATT_BUILDER_URL is not defined in the environment variables");
  }

  const formUrl = `${builderUrl}/${authoringPath}/${formId}`;

  return (
    <div>
      <img src="/D&C-logo.jpg" alt="Logo" className="w-60 mx-auto my-4 mb-5" />
      <Form src={formUrl} />
    </div>
  );
};

export default CatchAllForm;
