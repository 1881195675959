import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const IDME_FUNCTION_URL = process.env.REACT_APP_IDME_FUNCTION_URL!!

type MessageType =
  | "success"
  | "failure"
  | "loading"
  | "no_consent"
  | "error"
  | null;

type IDMEFunctionResponse = {
  status: "success" | "error";
  verified: boolean;
  message: string;
};

const IdmeThankYou: React.FC = () => {
  const [messageType, setMessageType] = useState<MessageType>(null); // starts in default stat
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = "ID.me Verification Status";
    return () => {
      document.title = ""; // Reset title when component unmounts
    };
  }, []);

  useEffect(() => {
    // if there is an error, the user did not consent
    if (searchParams.get("error") || searchParams.get("error_description")) {
      setMessageType("no_consent");
      return;
    }

    // else get the code
    const code = searchParams.get("code");

    // verify the code
    async function verifyCode() {
      if (!code) return; // if there is no code, return and show default case

      setMessageType("loading"); // show loading message

      try {
        // call the idme function
        const response = await axios.get(`${IDME_FUNCTION_URL}`, {
          params: { code },
        });

        // get the response data
        const { status, verified, message }: IDMEFunctionResponse =
          response.data;

        // log the response data
        console.log("Server Response:", {
          status,
          verified,
          message,
          responseStatus: response.status,
        });

        // set the message type based on the response
        if (status === "error") {
          setMessageType("error");
        } else {
          setMessageType(verified ? "success" : "failure");
        }
      } catch (error) {
        // if there is an error, log it
        if (axios.isAxiosError(error)) {
          console.error("ID.me API Error:", {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
          });
        } else {
          console.error("Unexpected error:", error);
        }
        // show error message
        setMessageType("error");
      }
    }

    // run the function
    verifyCode();
  }, [searchParams]); // run the function on search params change

  const renderMessage = () => {
    switch (messageType) {
      case "loading":
        return (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-600 mb-4">
              Verifying Identity
            </h2>
            <p className="text-lg">
              Please wait while we verify your identity...
            </p>
          </div>
        );
      case "success":
        return (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-green-600 mb-4">
              Verification Successful
            </h2>
            <p className="text-lg">
              Your identity has been successfully verified through id.me.
            </p>
          </div>
        );
      case "failure":
        return (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-tekmirDarkRed mb-4">
              Verification Failed
            </h2>
            <p className="text-lg">
              We were unable to verify your identity through id.me. Please try
              again using the original link.
            </p>
          </div>
        );
      case "no_consent":
        return (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-tekmirDarkRed mb-4">
              Denied Consent
            </h2>
            <p className="text-lg">
              We are unable to verify your identity without your consent. Return
              to the link and make your consent to proceed
            </p>
          </div>
        );
      case "error":
        return (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-tekmirDarkRed mb-4">
              Looks like something went wrong...
            </h2>
            <p className="text-lg">
              Please try again using the original link.
            </p>
          </div>
        );
      default:
        return (
          <div className="text-center">
            <p className="text-center text-lg text-gray-600">
              404 - Page not found
            </p>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen flex flex-col p-4">
      <div className="absolute top-4 left-4">
        <img src="/logo.png" alt="Logo" className="h-12 w-auto" />
      </div>
      <div className="flex-grow flex items-center justify-center">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
          {renderMessage()}
        </div>
      </div>
    </div>
  );
};

export default IdmeThankYou;
