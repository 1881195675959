import { Route, Routes } from 'react-router-dom'
import MedicalIntake from '../MedicalIntake'
import Layout from '../Layout/Layout'
import IdmeThankYou from '../Pages/IdmeThankYou/idmeThankYou';

const Router = () => {
    return (
        <Routes>
            <Route path="/initialprefs" element={<Layout><MedicalIntake /></Layout>} />
            <Route path="/idme" element={<Layout><IdmeThankYou/></Layout>} />
        </Routes>
    )
}

export default Router